<template>
  <div class="funfacts">

    <v-container>
      <v-row>
        <v-col cols = 7>
           <HelloWorld msg="Welcome to Your Vue.js App"/>
        </v-col>
        
        <v-col cols = 5>
           <PV />
        </v-col>
      </v-row>
    </v-container>
    
          <!-- <v-container>
        <v-row>
          <v-col
            v-for="n in 24"
            :key="n"
            cols="4"
          >
            <v-card height="200">
              <HelloWorld msg="Welcome to Your Vue.js App"/>
            </v-card>
          </v-col>
        </v-row>
      </v-container> -->

    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    HelloWorld: () => import('@/views/Routes.vue'),
    PV: () => import('@/views/PV.vue')
  }
}
</script>
